.cky-title {
    display: none !important;
    margin: 0 !important;
}
.cky-consent-container a,
.cky-consent-container p,
.cky-preference-center a,
.cky-preference-center p {
    color: black !important;
    font-size: 1rem !important;
    line-height: 1.25rem !important;
}
.cky-consent-bar {
    padding: 1.25rem !important;
}
.cky-btn {
    background-color: transparent !important;
    border: none !important;
    color: black !important;
    font-size: 1.5rem !important;
    line-height: 1.75rem !important;
    width: fit-content !important;
    flex: unset !important;
    padding: 0 !important;
    margin: 0 !important;
    margin-left: 1.5rem !important;
}
.cky-notice-btn-wrapper {
    padding: 0 !important;
    flex-direction: row !important;
}

.cky-notice-des {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.cky-accordion-header {
    position: relative !important;
}
@media (max-width: 767px) {
    .cky-notice-btn-wrapper {
        flex-direction: row-reverse !important;
        justify-content: flex-start !important;
    }
}
@media (min-width: 768px) {
    .cky-consent-bar {
        padding: 2rem !important;
    }
    .cky-notice-btn-wrapper {
        justify-content: flex-end !important;
    }
    .cky-notice-group {
        flex-direction: column !important;
        align-items: flex-end !important;
    }
    .cky-notice-des {
        margin-bottom: 1.5rem !important;
    }
}
@media (min-width: 1280px) {
    .cky-consent-container {
        max-width: 32rem !important;
        right: 0 !important;
        left: unset !important;
    }
}
/* Manager */
.cky-modal {
    border-radius: 0 !important;
}
.cky-modal * {
    border: none !important;
}
.cky-btn-close {
    position: absolute !important;
    top: 24px !important;
    right: 24px !important;
}
.cky-btn-close img {
    opacity: 0 !important;
}
.cky-btn-close::after {
    content: "Close" !important;
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
}
.cky-preference-header {
    padding-top: 3rem !important;
}
.cky-show-desc-btn {
    color: black !important;
}
.cky-accordion-header-des {
    margin-top: 0 !important;
    margin-bottom: 24px !important;
}

.cky-footer-shadow {
    display: none !important;
}

.cky-accordion-btn {
    font-size: 1.25rem !important;
    line-height: 1.5rem;
}

.cky-prefrence-btn-wrapper {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
}
.cky-accordion-header-des {
    padding-right: 3rem !important;
}
@media (max-width: 576px) {
    .cky-prefrence-btn-wrapper .cky-btn-accept {
        order: 10 !important;
    }
}
@media (min-width: 768px) {
    .cky-preference-header {
        padding-top: 2rem !important;
    }
    .cky-preference-header,
    .cky-preference-body-wrapper,
    .cky-prefrence-btn-wrapper {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
    .cky-prefrence-btn-wrapper {
        padding-top: 1.5rem !important;
        padding-bottom: 2rem !important;
    }
}
@media (min-width: 1280px) {
    .cky-modal {
        max-width: 50rem !important;
        right: 0 !important;
        bottom: 0 !important;
        top: unset !important;
        left: unset !important;
        transform: translate(100%, 0%) !important;
    }
    .cky-modal.cky-modal-open {
        transform: translate(0) !important;
    }
}
.cky-switch {
    position: relative;
}
.cky-always-active,
.cky-switch input[type="checkbox"] {
    position: absolute !important;
    color: transparent !important;
    font-size: 0 !important;
    right: 0 !important;
    z-index: 9 !important;
    width: 1.25rem !important;
    background: transparent !important;
    height: 1.25rem !important;
    border-radius: 0 !important;
    border: 2px solid #000000 !important;
    background: no-repeat 60% 50% white !important;
    background-size: 9px auto !important;
}

.cky-switch input[type="checkbox"]:before {
    display: none !important;
}

.cky-always-active {
    background: no-repeat 60% 50%
        url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='7'><path d='m.7 3.7 2.5 2.5M8.5.7 3.2 6.2' fill='none' stroke='white'/></svg>") !important;
    background-color: black !important;
    opacity: 0.4 !important;
}
.cky-switch input[type="checkbox"]:checked {
    background: no-repeat 60% 50%
        url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='7'><path d='m.7 3.7 2.5 2.5M8.5.7 3.2 6.2' fill='none' stroke='white'/></svg>") !important;
    background-color: black !important;
}
.cky-switch input[type="checkbox"]:not(:checked):hover {
    background-color: #0000004d !important;
}
[data-cky-tag="detail-powered-by"] {
    display: none !important;
}
