/* Team */

@media (min-width: 1280px) {
    .team_list-item .team_item-image-wrap {
        opacity: 0;
    }
    .team_list-item:nth-child(odd):hover .team_item-image-wrap {
        opacity: 1;
        transform: translateY(-50%) rotate(-4deg);
    }
    .team_list-item:nth-child(even):hover .team_item-image-wrap {
        opacity: 1;
        transform: translateY(-50%) rotate(4deg);
    }
}
@media (max-width: 1279.99px) {
    .team_list-item:nth-child(odd) .team_item-image-wrap {
        opacity: 1;
        transform: translateY(-50%) rotate(-4deg);
    }
    .team_list-item:nth-child(even) .team_item-image-wrap {
        opacity: 1;
        transform: translateY(-50%) rotate(4deg);
    }
}
