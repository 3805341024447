@import "https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css";
@import "./modules/css/case-studies";
@import "./modules/css/forms";
@import "./modules/css/marquee";
@import "./modules/css/nav";
@import "./modules/css/news";
@import "./modules/css/swiper";
@import "./modules/css/team";
@import "./modules/css/cookie-yes";
body.cursor-hidden {
    cursor: none !important;
}

/* .digital,
.soul,
.protocol {
    position: relative;
    transform: translateY(0);
} */
.hero_wrapper {
    min-height: 100vh;
}
